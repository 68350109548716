import React from 'react';
import { ReactComponent as analytics_light } from 'assets/neo_icons/Dashboard/Analytics_Light.svg'  
import { ReactComponent as analytics_dark } from 'assets/neo_icons/Dashboard/Analytics_Dark.svg'
import { ReactComponent as dash_light } from 'assets/neo_icons/Dashboard/dashboard.svg'
import { ReactComponent as dash_dark } from 'assets/neo_icons/Dashboard/dashboard_dark.svg'
import { ReactComponent as explore_light } from 'assets/neo_icons/Dashboard/explore.svg'
import { ReactComponent as explore_dark } from 'assets/neo_icons/Dashboard/explore_dark.svg'
import { ReactComponent as reports_light } from 'assets/neo_icons/Dashboard/reports.svg'
import { ReactComponent as reports_dark } from 'assets/neo_icons/Dashboard/reports_dark.svg'
import { ReactComponent as dash_lightActive } from 'assets/neo_icons/Dashboard/Dashboard_lightActive.svg'
import { ReactComponent as dash_darkActive } from 'assets/neo_icons/Dashboard/Dashboard_darkActive.svg'
import { ReactComponent as explore_lightActive } from 'assets/neo_icons/Dashboard/Explore_lightActive.svg'
import { ReactComponent as explore_darkActive } from 'assets/neo_icons/Dashboard/Explore_darkActive.svg'
import { ReactComponent as forms_lightActive } from 'assets/neo_icons/Dashboard/Form_lightActive.svg'
import { ReactComponent as forms_darkActive } from 'assets/neo_icons/Dashboard/Form_darkActive.svg'
import { ReactComponent as reports_lightActive } from 'assets/neo_icons/Dashboard/Report_lightActive.svg'
import { ReactComponent as reports_darkActive } from 'assets/neo_icons/Dashboard/Report_darkActive.svg'
import { ReactComponent as account_light } from 'assets/neo_icons/Menu/account.svg'
import { ReactComponent as account_dark } from 'assets/neo_icons/Menu/account_dark.svg'
import { ReactComponent as help_light } from 'assets/neo_icons/Menu/help.svg'
import { ReactComponent as help_dark } from 'assets/neo_icons/Menu/help_dark.svg'
import { ReactComponent as chat_support_light } from 'assets/neo_icons/Menu/chat_support.svg'
import { ReactComponent as chat_support_dark } from 'assets/neo_icons/Menu/chat_support_dark.svg'
import { ReactComponent as settings_light } from 'assets/neo_icons/Menu/settings.svg'
import { ReactComponent as settings_dark } from 'assets/neo_icons/Menu/settings_dark.svg'
import { ReactComponent as Production_light } from 'assets/neo_icons/Menu/Production_dark.svg'
import { ReactComponent as Production_dark } from 'assets/neo_icons/Menu/Production_light.svg'
import { ReactComponent as line_light } from 'assets/neo_icons/Dashboard/Line_light.svg'
import { ReactComponent as line_dark } from 'assets/neo_icons/Dashboard/Line_dark.svg'
import { ReactComponent as line_active } from 'assets/neo_icons/Dashboard/Line_active.svg'
import { ReactComponent as Sprint_History } from 'assets/Histrory.svg'
import { ReactComponent as Offline_instruments } from 'assets/neo_icons/Menu/Offline_Instruments.svg';
import { ReactComponent as Alarms_light } from 'assets/neo_icons/Dashboard/Alarm.svg';
import { ReactComponent as Tasks_light } from 'assets/neo_icons/Dashboard/Task.svg';
import { ReactComponent as Fault_Analysis } from 'assets/neo_icons/FaultAnalysis/FaultAnalysis.svg'
import { ReactComponent as Asset_Health } from 'assets/neo_icons/AssetHealth/AssetHealth.svg'
import { ReactComponent as Asset_HealthDark } from 'assets/neo_icons/AssetHealth/AssetHealth-Dark.svg'
import { ReactComponent as scada_light } from 'assets/neo_icons/Equipments/blower.svg'
import { ReactComponent as scada_dark } from 'assets/neo_icons/Equipments/blower_dark.svg'







import {ReactComponent as AlarmIcon} from  'assets/neo_icons/Menu/newMenuIcon/Alarms.svg'
import {ReactComponent as AnalyticsIcon} from  'assets/neo_icons/Menu/newMenuIcon/Analytics.svg'
import {ReactComponent as DashboardIcon} from  'assets/neo_icons/Menu/newMenuIcon/Dashboard.svg'
import {ReactComponent as ExploreIcon} from  'assets/neo_icons/Menu/newMenuIcon/Explore.svg'
import {ReactComponent as HelpIcon} from  'assets/neo_icons/Menu/newMenuIcon/Help.svg'
import {ReactComponent as Notifications} from  'assets/neo_icons/Menu/newMenuIcon/Notifications.svg'
import {ReactComponent as OfflineIcon} from  'assets/neo_icons/Menu/newMenuIcon/Offline.svg'
import {ReactComponent as PdMIcon} from  'assets/neo_icons/Menu/newMenuIcon/PdM.svg'
import {ReactComponent as ProductionIcon} from  'assets/neo_icons/Menu/newMenuIcon/Production.svg'
import {ReactComponent as ReportIcon}  from  'assets/neo_icons/Menu/newMenuIcon/Reports.svg'
import {ReactComponent as SettingsIcon}  from  'assets/neo_icons/Menu/newMenuIcon/Settings.svg'
import {ReactComponent as TaskIcon}  from  'assets/neo_icons/Menu/newMenuIcon/Tasks.svg'
import {ReactComponent as WhatsNewIcon}  from  'assets/neo_icons/Menu/newMenuIcon/WhatNew.svg'





const Dashboards = React.lazy(() => import("components/layouts/Dashboards/Dashboard"));
// const Scada = React.lazy(() => import("components/layouts/Scada/Scada"));




const Explore = React.lazy(() =>import("components/layouts/Explore/index"))
const Reports = React.lazy(() => import("components/layouts/Reports"));
const Analytics = React.lazy(() => import("components/layouts/Analytics/index.js"));
const Support = React.lazy(() => import("components/layouts/Support"));
const Settings = React.lazy(() => import("components/layouts/Settings/index.js"));
const RequestAccess = React.lazy(() => import("components/layouts/Profile/RequestAccess"));
const Activity = React.lazy(() => import("components/layouts/Profile/Activity"));
const Profile = React.lazy(() => import("components/layouts/Profile/ProfileSetting"));
const Line = React.lazy(() => import("components/layouts/Line/index"));
const OfflineDAQ = React.lazy(() => import("components/layouts/OfflineDAQ/index")); 
const Alarms = React.lazy(() => import("components/layouts/Alarms/index")); 
const Tasks = React.lazy(() => import("components/layouts/Tasks/NewTask")); 
const FaultAnalysis = React.lazy(() => import("components/layouts/FaultAnalysis/index"))
const AssetHealth = React.lazy(() => import("components/layouts/AssetHealth/index"))
const Production = React.lazy(() => import("components/layouts/Settings/Production/Production"));
const ReleaseNotes=React.lazy(() => import("components/layouts/Profile/ReleaseNotes/index"));

const Routes = {
  mainRoutes: [  
    {
      path: "/dashboard",
      name: "dashboard",
      iconLight: DashboardIcon,
      iconDark: DashboardIcon,
      activeLight: DashboardIcon,
      activeDark: DashboardIcon,
      component: Dashboards,
      layout: "/neo",
      iconFlag: true,
      footer: false,
      line: "/:line",
      type:'monitor'
    },
    // {
    //   path: "/scada",
    //   name: "scada",
    //   iconLight: scada_light,
    //   iconDark: scada_dark,
    //   activeLight: dash_lightActive,
    //   activeDark: dash_darkActive,
    //   component: Scada,
    //   layout: "/neo",
    //   iconFlag: true,
    //   footer: false,
    //   line: "/:line"
    // },
    {
      path: "/explore",
      name: "explore",
      iconLight: ExploreIcon,
      iconDark: ExploreIcon,
      activeLight: ExploreIcon,
      activeDark: ExploreIcon,
      component: Explore,
      layout: "/neo",
      iconFlag: true,
      footer: false,
      line: "/:line",
      type:'analyze'
    },
    
    {
      path: "/reports",
      name: "reports",
      iconLight: ReportIcon,
      iconDark: ReportIcon,
      activeLight: ReportIcon,
      activeDark: ReportIcon,
      component: Reports,
      layout: "/neo",
      iconFlag: true,
      footer: false,
      line: "/:line",
      type:"monitor",

    }, 
    {
      path: "/Alarms",
      name: "Alarms",
      iconLight: AlarmIcon,
      iconDark: AlarmIcon,
      activeLight: AlarmIcon,
      activeDark: AlarmIcon,
      component: Alarms,
      layout: "/neo",
      iconFlag: true,
      footer: false,
      line: "/:line",
      type:'act'
    },
    {
      path: "/Tasks",
      name: "Tasks",
      iconLight: TaskIcon,
      iconDark: TaskIcon,
      activeLight: TaskIcon,
      activeDark: TaskIcon,
      component: Tasks,
      layout: "/neo",
      iconFlag: true,
      footer: false,
      line: "/:line",
      type:'track'

    },
    {
      path: "/analytics",
      name: "analytics",
      iconLight: AnalyticsIcon,
      iconDark: analytics_dark,
      activeLight: AnalyticsIcon,
      activeDark: AnalyticsIcon,
      component: Analytics,
      layout: "/neo",
      iconFlag: true,
      footer: false,
      bottom: 0,
      line: "/:line",
      type:'analyze'


    },
    {
      path: "/FaultAnalysis",
      name: "PdM",
      iconLight: PdMIcon,
      iconDark: PdMIcon,
      activeLight: PdMIcon,
      activeDark: PdMIcon,
      component: FaultAnalysis,
      layout: "/neo",
      iconFlag: true,
      footer: false,
      line: "/:line",
      type:'act'


    },
    {
      path: "/AssetHealth",
name: "Asset Health",
iconLight: DashboardIcon,
iconDark: DashboardIcon,
activeLight: DashboardIcon,
activeDark: DashboardIcon,
      component: AssetHealth,
      layout: "/neo",
      iconFlag: true,
      footer: false,
      line: "/:line",
      type:"monitor",
      
    }, 
    {
      path: "/offline",
      name: 'offline',
      iconLight: OfflineIcon,
      iconDark: OfflineIcon,
      activeLight: OfflineIcon,
      activeDark: OfflineIcon,
      component: OfflineDAQ,
      layout: "/neo",
      iconFlag: true,
      footer: false,
      line: "/:line",
      type:"track"
      
    },
    // {
    //   path: "/line",
    //   name: "line",
    //   iconLight: line_light,
    //   iconDark: line_dark,
    //   activeLight: line_active,
    //   activeDark: line_active,
    //   component: Line,
    //   layout: "/neo",
    //   iconFlag: true,
    //   footer: false,
    //   line: "/:line"
    // },
    
    {
      path: "/profile",
      name: "profile",
      iconLight: account_light,
      iconDark: account_dark,
      activeLight: forms_lightActive,
      activeDark: forms_darkActive,
      component: Profile,
      layout: "/neo",
      iconFlag: false,
      footer: false,
      bottom: 0,
      line: "/:line",
    },
    {
      path: "/access",
      name: "access",
      iconLight: help_light,
      iconDark: help_dark,
      activeLight: forms_lightActive,
      activeDark: forms_darkActive,
      component: RequestAccess,
      layout: "/neo",
      iconFlag: false,
      footer: false,
      bottom: 0,
      line: "/:line"
    },
    {
      path: "/changeLog",
      name: "ChangeLog",
      iconLight: Sprint_History,
      iconDark: Sprint_History,
      activeLight: forms_lightActive,
      activeDark: forms_darkActive,
      component: ReleaseNotes,
      layout: "/neo",
      iconFlag: false,
      footer: false,
      bottom: 0,
      line: "/:line"
    },
    {
      path: "/activity",
      name: "Activity",
      iconLight: help_light,
      iconDark: help_dark,
      activeLight: forms_lightActive,
      activeDark: forms_darkActive,
      component: Activity,
      layout: "/neo",
      iconFlag: false,
      footer: false,
      bottom: 0,
      line: "/:line"
    },
   
   
   
    {
      path: "/production",
      name: "production",
      iconLight: ProductionIcon,
      iconDark: ProductionIcon,
      activeLight: ProductionIcon,
      activeDark: ProductionIcon,
      component: Production,
      layout: "/neo",
      iconFlag: true,
      footer: false,
      bottom: 0,
      line: "/:line",
      type:"track",
      isAccordian:true,
      AccordianOpen:false
    },
    
    
    {
      path: "/settings",
      name: "settings",
      iconLight: SettingsIcon,
      iconDark: SettingsIcon,
      activeLight: SettingsIcon,
      activeDark: SettingsIcon,
      component: Settings,
      layout: "/neo",
      iconFlag: true,
      footer: false,
      bottom: 0,
      line: "/:line",
      type:"footer",
      isAccordian:true,
      AccordianOpen:false
    },
    {
      path: "/support",
      name: "support",
      iconLight: HelpIcon,
      iconDark: HelpIcon,
      activeLight: HelpIcon,
      activeDark: HelpIcon,
      component: Support,
      layout: "/neo",
      iconFlag: false,
      footer: false,
      bottom: 0,
      line: "/:line",
      type:"footer"
    }, 
   
  ],
};

export default Routes;
